/* Navbar.css */
.nav-bg {
  background-image: linear-gradient(#F33C00, #EAAF39);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}



.navbar {
    display: flex;
    flex-direction: row ;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background-color: #E39E1F 0.885;
    color: #9A0106;
    width: 1200px;
  }
  
  .logo {
    font-size: 1.5rem;
  }
  
  .nav-items {
    list-style: none;
    display: flex;
  }
  
  .nav-items li {
    margin-right: 2rem;
    font-size: 20px;
  }
  
  .nav-items a {
    text-decoration: none;
    color: #9A0106;
    font-weight: 700;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-image: linear-gradient(360deg, #F33C00, #EAAF39);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    padding: 20px 0px 20px 15px;
    z-index: 1;
  }
  
  .dropdown-content li {
    display: block;
    border-bottom: 2px solid #ffffff;
    padding: 10px 0px 5px 0px;
    margin-right: 1rem;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  /* Mobile styles */
  .mobile-menu {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .bar {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 3px 0;
  }
  
  @media (max-width: 768px) {
    .nav-bg {
      padding-bottom: 20px;
    }

    .navbar {
      position: relative;
    }
  
    .mobile-menu {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      position: absolute;
      top: 15px;
      right: 15px;
    }
  
    .mobile-menu.open .bar:nth-child(1) {
      transform: rotate(-45deg) translate(-5px, 6px);
    }
  
    .mobile-menu.open .bar:nth-child(2) {
      opacity: 0;
    }
  
    .mobile-menu.open .bar:nth-child(3) {
      transform: rotate(45deg) translate(-5px, -6px);
    }
  
    .nav-items {
      list-style: none;
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 60px; /* Adjust based on your header height */
      left: 0;
      width: 100%;
      text-align: center;
      background-image: linear-gradient(360deg, #F33C00, #EAAF39);
      display: none;
    }
  
    .nav-items.mobile {
      display: flex;
      height: auto;
      z-index: 9999;
    }
  
    .nav-items li {
      margin: 0;
      padding: 1rem;
      
    }
  
    .dropdown-content {
      display: none;
      position: static;
      background-color: #7027B4;
      
    }
    .dropdown-content li {
        border-bottom: 0px;
    }

    .dropdown.mobile-dropdown .dropdown-content {
      display: block;
      position: relative;
    }
  }
  