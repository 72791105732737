.news-scroll {
    height: 45px; /* Adjust the height as needed */
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  
  .news-scroll div {
    position: absolute;
    width: 100%;
    transition: transform 0.5s ease-in-out;
    font-size: 24px;
    padding: 12px 12px 15px 20px;
  }
  
  .news-scroll div.active {
    transform: translateY(0);
  }
  
  .news-scroll div.inactive {
    transform: translateY(100%);
  }
  
  .dummy-news-list {
    margin: 40px 0px 0px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 1160px;
    border-bottom: 3px solid #F33C00;
  }

  .dummy-news-list h2 {
    width: 200px;
    background-color: #F33C00;
    padding: 12px 12px 12px 20px;
    color: #ffffff;
  }


  @media (max-width: 768px) {
    .news-scroll{
      height: 50px;
    }

    .dummy-news-list h2 {
      font-size: 16px;
      width: 100px;
    }

    .news-scroll div {
      font-size: 16px;
    }
  }
  