.banner {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 20px;
    box-sizing: border-box;
    width: 1200px;
    margin-top: 30px;
  } 
  
  .content-box {
    width: 100%;
    max-width: 500px;
    padding: 20px;
    margin: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
    text-align: center;
  }

  .content-box p {
    margin-top: 10px;
    font-size: 20px;
  }

  .video-sec {
    width: 1160px;
  }

  .video-main-sec {
    margin-bottom: 30px;
  }
  
  @media (max-width: 768px) {
    .app {
      flex-direction: column;
      align-items: center;
    }
    .video-sec {
      width: 100%;
    }
  }
  