/* styles.css */

.contact-us-container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 20px 20px 70px 20px;
    color: #000;
  }
  .contact-us-container h2 {
    font-size: 40px;
    text-align: center;
    margin: 20px 0px;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    margin-bottom: 8px;
    color: #000;
    font-size: 20px;
  }
  
  input,
  textarea {
    padding: 8px;
    margin-bottom: 16px;
    border-radius: 10px;
    height: 40px;
    border: 0px;
  }
  
  button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    width: 150px;
    height: 50px;
    font-size: 20px;
    margin-top: 20px;
    border: 0px;
    border-radius: 10px;
  }
  
#message {
    height: 200px;
}

  /* Media query for mobile responsiveness */
  @media (max-width: 600px) {
    form {
      width: 100%;
    }
  }
  