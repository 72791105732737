.events {
    margin: 30px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1200px;
    padding: 20px;
}

.events img {
    width: 690px;
    height: auto;
}

.events-heading {
    color: #000000;
    text-align: left;
    width: 100%;
    margin-bottom: 30px;
}

@media (max-width: 768px) {
    .events {
        width: 100%;
    }
    .events img {
        width: 100%;
        height: auto;
    }
}