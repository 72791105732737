.event-list-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .event-card {
    width: 100%;
    width: 300px;
    margin: 10px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    padding: 20px 0px;
    border-radius: 10px;
    margin: 30px;
  }

  .event-card img {
    width: 260px;
    height: 200px;
    border-radius: 10px;
  }

  .event-card h3 {
    font-size: 24px;
    margin-top: 15px;
  }
  
  @media (max-width: 768px) {
    .event-card {
        width: 100%;
        max-width: 400px;
        padding-top: 0px;
    }

    .event-card img {
        width: 100%;
        height: auto;
    }
  }

  /* Add more styling as needed */
  