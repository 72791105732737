

.banner {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 20px;
    box-sizing: border-box;
    width: 1200px;
  }
  
.quote-cont{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.quote{
  border: 3px solid #000;
  margin-top: -25px;
  width: 40px;
  color: #000;
  border-radius: 50%;
  font-size: 30px;
  text-align: center;
}

  .client-box {
    width: 100%;
    max-width: 450px;
    padding:  0px 30px 30px 30px;
    margin: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    background-color: #000 0.75;
    text-align: center;
    color: #000;
  }

  .client-box .content {
    margin-top: 20px;
    text-align: left;
    font-size: 20px;
  }

  .client-box .name {
    text-align: left;
    font-weight: bold;
    margin-top: 30px;
    font-size: 20px;
  }
  
  .client-box .designation {
    text-align: left;
    margin-top: 0px;
    font-size: 20px;
  }

  @media (max-width: 768px) {
    .app {
      flex-direction: column;
      align-items: center;
    }
  }
  