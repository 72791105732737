.gallery {
  width: 1140px;
  margin-bottom: 30px;
}

.gallery h2 {
  margin: 20px 0px 20px 0px;
  font-size: 36px;
}

.img-pad {
  padding-right: 5px;
}


.img-pad img {
  max-height: 250px; /* Set your fixed height here */
  object-fit: cover; /* Maintain aspect ratio */
}
/* Responsive Styles */
@media (max-width: 768px) {
  .gallery {
    width: 100%;
  }

  .gallery h2 {
    text-align: center;
  }
}





.marquee-container {
  width: 1140px; /* Adjust the width of the container as needed */
  overflow: hidden;
  margin: 30px 0px;
}

.marquee-content {
  display: flex;
  animation: marquee 10s linear infinite; /* Adjust the duration and timing function as needed */
}

.marquee-image {
  width: 300px; /* Adjust the width of each image as needed */
  height: auto; /* Maintain aspect ratio */
  margin-right: 10px; /* Adjust the spacing between images */
  
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
