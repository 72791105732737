.head-bg {
    background-color: #F33C00;
}
.header {
    width: 1200px;
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row ;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 20px;
}

.logo {
    width: 150px;
}

.head-cont{
    padding: 0px 0px 0px 20px;
}

.head-cont .heading {
    font-size: 40px;
    color: #ffffff;
}
.head-cont .quotes {
    font-size: 20px;
    color: #ffffff;
}

.head-cont .telugu {
    font-size: 35px;
    color: #EBD306;
    margin-top: 10px;
}

@media (max-width: 768px) {
    .head-cont .heading {
        font-size: 12px;
        margin-bottom: 6px;
    }

    .head-cont .quotes {
        font-size: 7px;
    }

    .head-cont .telugu {
        font-size: 12px;
    }
    .logo {
        width: 100px;
    }

    .header {
        height: 150px;
    }
} 