/* Footer.css */

.footer-container {
    background-image: linear-gradient(360deg, #F33C00, #EAAF39);
    color: #000;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    text-align: center;
    width: 1200px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  
  .footer-section {
    flex: 1;
    max-width: 300px;
    margin: 10px;
  }

  .footer-section h3 {
    font-size: 26px;
  }
  
  .footer-section p {
    font-size: 20px;
    margin-top: 10px;
    cursor: pointer;
    font-weight: 500;
  }

  .footer-section p:hover{
    color: #000;
  }


  .social-icons ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
  }
  
  .social-icons li {
    margin: 10px 10px;
  }
  
  .social-icons a {
    text-decoration: none;
    color: #000;
    font-size: 35px;
  }

  .developer-name {
    font-size: 14px;
  }

  .copy-right-bg {
    background-color: #000000;
  }
  
  .copy-right {
    display: flex;
      flex-direction: row ;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      color: #fff;
      width: 1200px;
  }
  
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-section {
      max-width: 100%;
    }
    .copy-right {
      flex-wrap: wrap;
    }

    .copy-right p {
      font-size: 10px;
    }
  }
  