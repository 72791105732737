.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.image-container {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.image-container img {
  width: 100%;
  height: auto;
  max-height: 650px;
}

.arrow {
  position: absolute;
  top: 50%;
  font-size: 24px;
  color: #333;
  cursor: pointer;
}

.left {
  left: 10px;
}

.right {
  right: 10px;
}

.dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.hide{
  display: none;
}
.active{
  display: block;
}
.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: #333;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .arrow {
    font-size: 18px;
  }

  .dots {
    bottom: 5px;
  }

  .dot {
    width: 8px;
    height: 8px;
    margin: 0 4px;
  }
  .image-container img {
    width: 100%;
    height: auto;
  }
}
