.about {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 1200px;
    margin: 50px 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.about-inside-cont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    padding: 30px 0px 30px 0px;
}

.about-text-cont{
    padding: 0px 50px 0px 0px;
}

.about-text-cont h1 {
    font-size: 40px;
    margin: 0px 0px 20px 0px;
}

.about-text-cont p {
    font-size: 20px;
    margin: 0px 0px 10px 0px;
}

.about-img-cont {
    padding: 10px;
}

.about-img-cont img {
    width: 400px;
}

  /* Responsive Styles */
  @media (max-width: 768px) {
    .about {
        width: 90%;
    }

    .about-text-cont{
        padding: 0px 0px 0px 0px;
    }

    .about-img-cont img {
        width: 100%;
    }
  }
  