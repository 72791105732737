@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700;900&display=swap');


* {
  padding: 0px;
  margin: 0px;
  font-family: "Roboto";
  box-sizing: border-box;
}

body {
  background-color: #F3E4FF;
  
}

.main-bg {
  background-image: url("./components/images/bg-img2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.main-cont{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.deskHide {
  display: none;
}
.deskShow {
  display: block;
}
.mobkHide {
  display: block;
}
.mobShow {
  display: none;
}

@media (max-width: 768px) {
  .mobkHide {
    display: none;
  }
  .mobShow {
    display: block;
  }
  .deskHide {
    display: block;
  }
  .deskShow {
    display: none;
  }
}


